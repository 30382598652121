<template>
  <div>


    <!-- {{selected}} -->
    <div class="container-fluid">

      <div v-if="selectedItems.length === 0">
        <p>Please select an order to change order details or other actions.</p>
      </div>


      <div v-else class="row">
        <div class="col-12 mb-1">
          <!-- <b-dropdown  variant="primary" text="Select Status" class="mr-1">
            <b-dropdown-item @click="progressFilter('All')">All</b-dropdown-item>
            <b-dropdown-item @click="progressFilter('Shipped')">Shipped</b-dropdown-item>
            <b-dropdown-item @click="progressFilter('Cancelled')">Cancelled</b-dropdown-item>
            <b-dropdown-item @click="progressFilter('Archived')">Archived</b-dropdown-item>
          </b-dropdown> -->

          <!-- <b-button class="ml-1" @click="generatePdf" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
            PDF Generate Label
          </b-button> -->

          <b-dropdown variant="primary" text="Actions" class="mr-1">
            <b-dropdown-item @click="updateOrderProgress(selectedItems, 'Shipped')">
              <template>
                <feather-icon icon="TruckIcon" size="15" class="text-body cursor-pointer" />
              </template>
              Mark As Shipped
            </b-dropdown-item>
            <b-dropdown-item @click="updateOrderProgress(selectedItems, 'Cancelled')">
              <template>
                <feather-icon icon="XIcon" size="15" class="text-body cursor-pointer" />
              </template>
              Mark As Cancelled
            </b-dropdown-item>
            <b-dropdown-item @click="updateOrderProgress(selectedItems, 'Archived')">
              <template>
                <feather-icon icon="ArchiveIcon" size="15" class="text-body cursor-pointer" />
              </template>
              Mark As Archived
            </b-dropdown-item>
            <b-dropdown-item @click="addTrackingInfo(selectedItems)">
              <template>
                <feather-icon icon="PlusIcon" size="15" class="text-body cursor-pointer" />
              </template>
              Add Tracking Info
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-button class="btn-mar" v-on:click="generatePdf" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary">
              PDF Generate Label

            </b-button>
          </b-dropdown>


        </div>
      </div>
    </div>
    <p style="margin-bottom: 3%;">Total Rows : <b>{{ totalRows }}</b></p>


    <div class="fliex">

      <b-col md="2" sm="3" class="ml-1">
        <b-dropdown class="fliex" text="All">
          <b-dropdown-item @click="progressFilter('All')">All</b-dropdown-item>
          <b-dropdown-item @click="progressFilter('Shipped')">Shipped</b-dropdown-item>
          <b-dropdown-item @click="progressFilter('Cancelled')">Cancelled</b-dropdown-item>
          <b-dropdown-item @click="progressFilter('Archived')">Archived</b-dropdown-item>
        </b-dropdown>
      </b-col>

      <b-col md="7" sm="4" class="ml-1">
        <b-form-group label-cols-sm="3" label-align-sm="right" label-size="md" label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="clear()">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>



      <b-col md="3">
        <b-form-group label-cols-sm="4" label-align-sm="right" label-size="sm" label-for="perPageInput">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
            @change="savePerPage(perPage)" />
        </b-form-group>
      </b-col>

    </div>

    <b-table :tbody-tr-class="rowClass" :items="filteredRows" :fields="fields" striped responsive id="my-table"
      :per-page="perPage" :current-page="currentPage" small :filter="filter" :filter-included-fields="filterOn"
      @filtered="onFiltered">

      <template #cell(orderID)="row">
        <span>{{ row.item.salesRecord }}</span>
        <br><br>
        <span>{{ row.item.orderID }}</span>
        <br><br>
        <small>{{ row.item.creation }}</small>
        <br>
        <span class="cursor-pointer" @click="row.toggleDetails">
          <feather-icon v-if="!row.detailsShowing" v-model="row.detailsShowing" icon="PlusIcon" />
          <feather-icon v-else icon="MinusIcon" v-model="row.detailsShowing" />
          <span>{{ row.detailsShowing ? 'Hide' : 'Show Details' }}</span>
        </span>

      </template>

      <template #cell(select)="row">
        <b-form-checkbox v-model="row.item.checkboxStatus" v-on:change="selected(row.item.orderID)"
          class="custom-control-primary">
          <feather-icon v-b-tooltip.hover title="Label has been generated" v-if="row.item.generated == 1"
            icon="TagIcon" />
        </b-form-checkbox>
        <small>{{ row.item.label_creation_dt }}</small>
      </template>

      <template #cell(image)="row">
        <div>
          <span class="mt-1" style="list-style-type: none;" v-for="product in row.item.products" :key="product.id">
            <div v-lazy-container="{
              selector: 'img',
              error:
                'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
              loading:
                'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
            }">
              <img :src="product.productImageUrl" width="100px">
              <br>
            </div>
          </span>
        </div>

      </template>

      <template #cell(sku)="row">
        <div v-for="product in row.item.products" :key="product.id">
          <span>{{ product.sku }}</span>
        </div>
      </template>


      <template #cell(status)="row">
        <div>
          <span>{{ row.item.orderProgress }} </span>

          <b-dropdown variant="link" no-caret class="chart-dropdown" toggle-class="p-0" right>
            <template #button-content>
              <feather-icon v-b-tooltip.hover.top="'Select Status'" icon="EditIcon" size="15" class="text-body cursor-pointer" />
            </template>
            <b-dropdown-item @click="updateOrderProgress([row.item.orderID], 'Shipped')">
              <template>
                <feather-icon icon="TruckIcon" size="15" class="text-body cursor-pointer" />
              </template>
              Mark As Shipped
            </b-dropdown-item>
            <b-dropdown-item @click="updateOrderProgress([row.item.orderID], 'Cancelled')">
              <template>
                <feather-icon icon="XIcon" size="15" class="text-body cursor-pointer" />
              </template>
              Mark As Cancelled
            </b-dropdown-item>
            <b-dropdown-item @click="updateOrderProgress([row.item.orderID], 'Archived')">
              <template>
                <feather-icon icon="ArchiveIcon" size="15" class="text-body cursor-pointer" />
              </template>
              Mark As Archived
            </b-dropdown-item>
            <b-dropdown-item @click="addTrackingInfo(row.item.orderID)">
              <template>
                <feather-icon icon="Plus Icon" size="15" class="text-body cursor-pointer" />
              </template>
              Add Tracking Info
            </b-dropdown-item>
          </b-dropdown>
          <br>
          <div class="mt-1">
            <span>Tracking Info: {{ row.item.trackinginfo }}</span>
          </div>
        </div>
      </template>

      <template #cell(price)="row">
        <div>
          <span v-for="product in row.item.products" :key="product.id">
            AU ${{ product.price }}
            <br><br>
          </span>
        </div>
      </template>

      <template #cell(title)="row">
        <div>
          <span v-for="product in row.item.products" :key="product.id">
            <span>{{ product.product_name }}</span>
            <br><br>
          </span>
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col md="4" class="mb-1">
              <strong>Buyer : </strong>{{ row.item.buyerName }}
            </b-col>

            <b-col md="4" class="mb-1">
              <strong>Status : </strong>{{ row.item.orderStatus }}
            </b-col>

            <b-col md="4" class="mb-1">
              <strong>Username </strong>{{ row.item.buyerEbayUserName }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Phone : </strong>{{ row.item.phone }}
            </b-col>

            <b-col md="4" class="mb-1">
              <strong>Carrier : </strong>{{ row.item.carrier }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Ship to : </strong>{{ row.item.ShipTo }} {{ row.item.postcode }}
            </b-col>

          </b-row>

          <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
            Hide Details
          </b-button>
        </b-card>
      </template>


    </b-table>

    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" first-number last-number
      class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="onPageChangeByUser">
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>

    <b-overlay :show="show" no-wrap spinner-variant="primary" />
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge, BPagination, VBTooltip,
  BDropdown, BDropdownItem, BDropdownDivider, BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BOverlay
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BOverlay
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      selectedOrderId: '',
      pageOptions: [25, 50, 100, 200],
      perPage: '25',
      currentPage: 1,
      totalRows: 1,
      filter: null,
      filterOn: [],
      show: false,
      fields: ['select', 'orderID', 'image', 'sku', 'status', 'price', 'title'],
      orders: [],
      selectedItems: [],
      customFilter: 'All',
      trackingform: {
        orderid: '',
        trackinfo: '',
        pageChangeCausedByUserInteraction: false,
      }
    }
  },
  computed: {

    filteredRows() {
      const filterItem = this.customFilter
      return this.orders.filter(function (order) {
        if (filterItem == 'All') {
          return true
        }
        if (filterItem !== 'All') {
          if (order.orderProgress) {
            return order.orderProgress.match(filterItem)
          }
        }
      })
    }

  },
  mounted() {
    this.perPage = '25'
    this.get()
  },
  watch: {
    currentPage(newPage, oldPage) {
      // Scroll to the top of the main container when the page changes
      if (newPage !== oldPage) {
        // Check if the change in currentPage is not caused by user interaction (e.g., selecting a row)
        // This prevents scrolling when selecting a row
        if (newPage !== oldPage && !this.pageChangeCausedByUserInteraction) {
          this.scrollToTop();
        }
        // Reset the flag for the next page change
        this.pageChangeCausedByUserInteraction = false;
      }
    },

    currentPage(newPage, oldPage) {
      // Scroll to the table element when the page changes
      if (newPage !== oldPage) {
        this.delayedScrollToTable();
      }
    },

  },

  methods: {
    scrollToTop() {
      const mainContainer = this.$refs.mainContainer;
      if (mainContainer) {
        mainContainer.scrollTop = 0;
      }
    },
    delayedScrollToTable() {
      setTimeout(() => {
        const tableToScroll = this.$refs.tableToScroll;
        if (tableToScroll) {
          tableToScroll.$el.scrollIntoView();
        }
      }, 100); // Adjust the delay as needed
    },
    onPageChangeByUser() {
      this.pageChangeCausedByUserInteraction = true;
    },
    async get() {
      try {
        this.show = true
        await axios.get(process.env.VUE_APP_API + '/processing-orders')
          .then(response => {
            this.orders = response['data']
            this.show = false
            this.totalRows = this.orders.length
          })
          .catch(error => {
            this.show = false
            console.log(error)
          })
      }
      catch (error) {
        this.show = false
      }
    },

    removeSelectedItems(ids) {
      for (const id of ids) {
        const index = this.selectedItems.indexOf(id);
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },

    async updateOrderProgress(ids, status) {
      // alert('123423')
      await axios({
        method: 'post',
        url: process.env.VUE_APP_API + '/update-order-progress',
        data: { 'orderId': ids, 'status': status }
      })
        .then(() => {
          this.get();

          // Show a notification based on the status
          let notificationMessage = '';
          if (status === 'Shipped') {
            notificationMessage = 'Product marked as Shipped successfully.';
          } else if (status === 'Cancelled') {
            notificationMessage = 'Product marked as Cancelled successfully.';
          } else if (status === 'Archived') {
            notificationMessage = 'Product marked as Archived successfully.';
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: notificationMessage,
              variant: 'success',
            },
          });
          // Use setTimeout to delay the refresh by a short time (e.g., 500 milliseconds)
          setTimeout(() => {
        this.get();
        this.selectedItems = this.selectedItems.filter(id => !ids.includes(id)); // Remove selected items after action
      }, 500);
    })
        .catch((error) => {
          console.error('Error marking product:', error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              text: 'Failed to mark product. Please try again.',
              variant: 'danger',
            },
          });
        });
    },

    progressFilter(val) {
      this.customFilter = val
      this.totalRows = this.filteredRows.length;
    },
    addTrackingInfo(id) {
      this.trackingform.orderid = id
    },
    saveTrackingInfo() {

      if (this.trackingform.trackinfo.length != 0) {
        this.$axios({
          method: 'post',
          url: '/add-tracking-info',
          data: this.trackingform
        })
          .then(res => {
            if (res.data.success = true) {
              this.$notify({
                type: "success",
                message: "Tracking Info added successfully",
              });
            }
            else if (res.data.success = false) {
              this.$notify({
                type: "danger",
                message: "Tracking Info could not be added",
              });
            }
          })
        this.modal_show = false
        this.trackingform.orderid = ''
        this.trackingform.trackinfo = ''

        this.get()
      }
      else {
        this.$notify({
          type: "danger",
          message: "Tracking Info empty",
        });
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    selected(id) {
      var status = false

      for (var i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i] == id) {
          status = true
        }
      }
      if (status) {
        const index = this.selectedItems.indexOf(id);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
      else {
        this.selectedItems.push(id)

      }
    },
    generatePdf() {
      if (this.selectedItems.length != 0) {
        axios({
          method: 'post',
          url: process.env.VUE_APP_API + '/shipping/pdf',
          data: this.selectedItems
        })
          .then(res => {
            window.open(process.env.VUE_APP_PDF_LABEL_CDN + `/${res.data}`, '_blank')
            axios.get(process.env.VUE_APP_API + '/vieworders')
              .then(res => this.orders = res['data'])
          })

        for (let index = 0; index < this.orders.length; index++) {
          const element = this.orders[index];
          element.checkboxStatus = false
        }
        this.selectedItems = []
      }
      else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No row(s) selected',
            icon: 'AlertOctagonIcon',
            text: 'Select row(s) to generate pdf labels',
            variant: 'danger',
          },
        },
          {
            position: 'top-center'
          })
      }
    },
    openEbay(id) {
      window.open(`https://www.ebay.com.au/itm/${id}`, '_blank')
    },
    rowClass(item, type) {
      const isLabelGenerated = 'table-success';
      const isInternational = item.carrier.toLowerCase().includes('international');

      if (!item || type !== 'row') {
        return;
      }

      if (item.generated == true) {
        if (isInternational) {
          return `${isLabelGenerated} international-label`;
        } else {
          return isLabelGenerated;
        }
      }

      if (isInternational) {
        return 'international-row'; // Apply a different class for international rows
      }

      // If none of the conditions match, return null or an empty string to apply default styling.
      return null; // or return '';
    },
    async markLabelAsGenerated() {
      try {
        if (this.selectedItems.length != 0) {
          this.show = true
          await axios({
            method: 'post',
            url: process.env.VUE_APP_API + '/mark-label-generated',
            data: this.selectedItems
          })
            .then(res => {
              if (res.data.success) {

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'AlertOctagonIcon',
                    text: 'Marked as Label Generated successfully',
                    variant: 'success',
                  },
                },
                  {
                    position: 'top-right'
                  })
                this.get()
                this.show = false
              }
              else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Failure',
                    icon: 'AlertOctagonIcon',
                    text: 'Marked as Label Generated Failed',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-right'
                  })
              }
            }
            )

          for (let index = 0; index < this.orders.length; index++) {
            const element = this.orders[index];
            element.checkboxStatus = false
          }
          this.selectedItems = []
        }
        else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No row(s) selected',
              icon: 'AlertOctagonIcon',
              text: 'Select row(s) to mark label generated',
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        }
      }
      catch (error) {
        this.show = false
        return []
      }
    },
    isLabelBlackListed(carrier, products) {

      let title = products[0]['product_name'].toLowerCase();
      if (carrier.toLowerCase().includes('international')) {
        if (title.includes('grille') || title.includes('headlights') || title.includes('bumper bar')) {
          return true
        }
      }
      else {
        return false
      }
    },
  }
}
</script>

<style>
.fliex {
  display: flex;
  gap: 1%;
  justify-content: end;
  margin-top: -6%;
  margin-left: 60%;
}

.btn-mar {
  margin: 12%;
  /* margin-top:1%; */
}

.fx {
  margin-top: -10%;
}

.international-row {
  background-color: #ffcccb;
  /* Change to the desired background color for international rows */
  color: #000;
  /* Change to the desired text color for international rows */
}
</style>